/**
CREATIVE CODING
---
Kill server : CTRL + C
Start server : npm run start
Start secure server : npm run start-https
Final build : npm run build
---
To generate new certificate for https connection with external device run :
#sh
mkcert 0.0.0.0 localhost 127.0.0.1 yourLocalIP ::1
mv 0.0.0.0+4-key.pem certificate.key
mv 0.0.0.0+4.pem certificate.cert
**/

// import Playground from "@onemorestudio/playgroundjs";
import AnimatedLogo from "./AnimatedLogo";
export default class App {
  constructor() {
    this.logo = new AnimatedLogo();
    this.mail = document.querySelector(".email-container");
    this.shop = document.querySelector(".shop-container");
    this.logo.addEventListener("complete", this.showmail.bind(this));
    setTimeout(() => {
      this.logo.play();
    }, 1000);
  }

  showmail() {
    this.mail.classList.add("show");
    setTimeout(() => {
      this.shop.classList.add("show");
    }, 1000);
  }

  //draw() {
  //  requestAnimationFrame(this.draw.bind(this));
  //}
}
