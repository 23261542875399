import Lottie from "lottie-web";
import EventEmitter from "@onemorestudio/eventemitterjs";
export default class AnimatedLogo extends EventEmitter {
  constructor() {
    super();
    this.container = document.querySelector(".lottie-container");
    this.animation = Lottie.loadAnimation({
      container: this.container,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "./json/animation.json",
    });
  }
  play() {
    this.animation.play();
    this.animation.addEventListener("complete", () => {
      this.emit("complete", []);
    });
  }
}
